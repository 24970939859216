import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import CurrencyFormat from "react-currency-format";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdGroup } from "react-icons/md";


const UpdatePlanAccount = ({
    accountId,
    defaultValue,
    staff,
    closedAt,
    approveAt,
    editMode
}) => {
    const [hasError, setHasError] = useState(false);

    const [oldPlanAccount, setOldPlanAccount] = useState(null);

    const [currentValue, setCurrentValue] = useState('');

    const [canUpdate, setCanUpdate] = useState({ message: '', canUpdate: false });

    const [{ data: updatePlanAccountData, loading: loadingUpdatePlanAccount }, updatePlanAccount] = useAxios({ url: editMode ? `/temporary-planning-accounts/${accountId}` : `/plan-accounts/${accountId}`, method: 'PUT' }, { useCache: false, manual: true });

    const [{ loading: loadingGetPlanAccountExpense }, getPlanAccountExpense] = useAxios({ url: `/plan-accounts/${accountId}/expense`, method: 'GET' }, { useCache: false, manual: true });

    useEffect(() => {
        if (accountId) {
            if (editMode) {
                setCanUpdate({ canUpdate: true, message: 'Por favor ingrese el monto' })
            } else {
                if (!closedAt && !approveAt) {
                    setCanUpdate({ canUpdate: true, message: 'Por favor ingrese el monto' })
                } else {
                    setCanUpdate({ canUpdate: false, message: 'No puedes actualizar la cuenta cuando el plan esta cerrado o aprobado.' });
                }
            }
        } else {
            setCanUpdate({ canUpdate: false, message: 'No puedes actualizar la cuenta.' });
        }
    }, [accountId, closedAt, approveAt, editMode])

    useEffect(() => {
        if (defaultValue) setCurrentValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e) => {
        setHasError(false);
        try {

            setCurrentValue(e.target.value);

            updatePlanAccount({
                data: {
                    amount: Number(e.target.value),
                    applyForAllMonths: 'no'
                }
            });

        } catch (error) {
        }
    }

    const handleExpense = () => {
        getPlanAccountExpense().then((response) => {
            console.log(response?.data);
            if (!response?.data) {
                setOldPlanAccount('vacio');
            } else {
                setOldPlanAccount(response?.data);
            }
        });
    }

    return (
        <div style={{ width: 150 }}>
            <div className="d-flex">
                {
                    staff ?
                        <MdGroup className="text-primary mb-1" style={{ fontSize: 14, cursor: 'pointer' }} title="Esta cuenta es afectada por la dotación del mes" />
                        :
                        null
                }
            </div>
            <div className="input-group flex-nowrap">
                <CurrencyFormat
                    className={clsx(["form-control"], {
                        'border border-danger': hasError,
                        'border border-success': !hasError && currentValue > 0,
                        'border border-warning': !hasError && currentValue < 0,
                        'cursor-not-allowed': !canUpdate.canUpdate
                    })}
                    value={currentValue}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    readOnly={!canUpdate.canUpdate}
                    title={canUpdate.message}
                    suffix="$"
                    onValueChange={(e) => handleChange({ target: { name: 'amount', value: e.value } })}
                    placeholder="Por favor ingrese el monto"
                />
                {
                    loadingUpdatePlanAccount &&
                    <span className="input-group-text" style={{ background: 'transparent' }}>
                        <Spinner variant="primary" style={{ width: 20, height: 20 }} animation="grow" />
                    </span>
                }
                {
                    !loadingUpdatePlanAccount && hasError ?
                        <span className="input-group-text p-0" style={{ background: 'transparent' }}>
                            <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                        </span>
                        :
                        null
                }
            </div>
            {
                accountId ?
                    loadingGetPlanAccountExpense ?
                        <Spinner variant="primary" style={{ width: 20, height: 20 }} animation="grow" />
                        :
                        <a title="Pulse para actualizar" onClick={() => handleExpense()} className={clsx(["custom-link d-block mt-1"], {
                            'text-primary': oldPlanAccount?.id,
                            'text-danger': oldPlanAccount === 'vacio',
                        })} style={{ cursor: 'pointer' }}>
                            {
                                oldPlanAccount ?
                                    oldPlanAccount === 'vacio' ?
                                        'No hay registros.'
                                        :
                                        `Gasto año anterior: ${Number(oldPlanAccount?.expense)?.toLocaleString()}$`
                                    :
                                    '- Ver gasto año anterior'
                            }
                        </a>
                    :
                    null

            }
        </div>
    )
}

export default UpdatePlanAccount;