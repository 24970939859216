import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountColumns from "../../../components/CustomTable/Columns/AccountColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useAccounts from "../../../hooks/useAccounts";
import useAccountClassifications from "../../../hooks/useAccountClassifications";
import UserHavePermission from "../../../util/UserHavePermission";
import { mainPermissions } from "../../../util/MenuLinks";

const Accounts = () => {

    const entity = {
        name: 'Cuentas',
        url: 'accounts',
        frontendUrl: '/cuentas',
        camelName: 'accounts',
    };

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        code: '',
        name: '',
        accountClassificationId: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ [entity.camelName]: records, total, numberOfPages, loading }, getRecords] = useAccounts({ params: { ...filters }, options: { useCache: false } });

    const [{ accountClassifications }] = useAccountClassifications();

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        getRecords({
            params: filters
        });
    }, [filters])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registros'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }
    }, [deleteError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(records?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteRecord({ url: `${entity.url}/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'El registros ha sido eliminado exitosamente.',
                show: true
            });
            getRecords();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (numberOfPages >= page && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleDeleteSelected = () => {
        deleteRecord({ url: `${entity.url}/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido eliminados exitosamente.',
                show: true
            })
            setSelectedValues([]);
            getRecords();
        });
    }

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    return (
        <div>
            <div className="my-4 justify-content-end d-flex">
                {
                    <>
                        {
                            UserHavePermission(mainPermissions?.accounts[2]) &&
                            <Link to={`${entity.frontendUrl}/importar`} className="btn btn-success me-1">
                                Importar
                            </Link>
                        }
                        {
                            UserHavePermission(mainPermissions?.accounts[1]) &&
                            <Link to={`${entity.frontendUrl}/crear`} className="btn btn-primary">
                                Crear {entity.name}
                            </Link>
                        }
                    </>
                }
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <label className="text-primary">Codigo</label>
                            <input
                                className="form-control"
                                placeholder="Codigo..."
                                type="text"
                                onChange={handleChange}
                                name="code"
                                value={filters?.code}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <label className="text-primary">Nombre</label>
                            <input
                                className="form-control"
                                placeholder="Nombre..."
                                type="text"
                                onChange={handleChange}
                                name="name"
                                value={filters?.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <label className="text-primary">Clasificación</label>
                            <select name="accountClassificationId" className="form-control" value={filters?.accountClassificationId} onChange={handleChange}>
                                <option value="">Seleccione una opción</option>
                                {
                                    accountClassifications?.map(accountClassification => {
                                        return (
                                            <option value={accountClassification?.id} key={accountClassification?.id}>
                                                {accountClassification?.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={entity?.name}
                entity={entity.url}
                updatePath={entity.frontendUrl}
                updateOptionString={'Editar'}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={records}
                currentPage={filters?.page}
                collumns={AccountColumns}
                changePage={handlePageChange}
                filters={filters}
                excelUrl={`${entity.url}/export/excel`}
                perPage={filters?.perPage}
                onPerPageChange={(e) => setFilters((oldFilters) => {
                    return {
                        ...oldFilters,
                        [e.target.name]: e.target.value
                    }
                })}
            />
        </div>
    )
}

export default Accounts;