import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import clsx from "clsx";
import { AiFillExclamationCircle } from "react-icons/ai";

const PlanStaffComponent = ({ staff, userCanUpdate, pathForStaffUpdate }) => {

    const [currentStaff, setCurrentStaff] = useState(null);

    const [{ data: updatePlanStaffResponse, loading: updatePlanStaffLoading, error }, updatePlanStaff] = useAxios({ url: pathForStaffUpdate, method: 'PUT' }, { manual: true, useCache: false });

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error])

    useEffect(() => {
        if (staff) {
            setCurrentStaff(staff);
        }
    }, [staff])

    const handleChange = (e) => {

        setHasError(false);

        if (!userCanUpdate || !staff?.staffId) return alert('No tienes permisos.');

        if (currentStaff?.staffId) {
            updatePlanStaff({
                data: {
                    amount: e.target.value,
                    applyForAllMonths: 'no'
                }
            });
        }

        setCurrentStaff((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleApply = () => {

        setHasError(false);

        if (!userCanUpdate || !staff?.staffId) return alert('No tienes permisos.');

        swal({
            title: "¿Estas Seguro(a)?",
            text: "Esto sobreescribira los valores de los demas meses.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willUpdate) => {
            if (willUpdate) updatePlanStaff({
                data: {
                    amount: Number(currentStaff?.total),
                    applyForAllMonths: 'si'
                }
            })
        })

    }

    return (
        <>
            <h4>
                Dotación:
            </h4>
            <div className="form-group mb-3">
                <label className="text-primary">
                    Asignada:
                </label>
                <input readOnly type="text" value={Number(currentStaff?.assigned).toLocaleString()} className="form-control" />
            </div>

            <div className="form-group mb-3">
                <div className="d-flex align-items-center justify-content-between">
                    <label htmlFor="" className="text-primary">
                        Solicitada
                    </label>
                    {
                        userCanUpdate && staff?.staffId ?
                            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={handleApply}>
                                Aplicar a los demas meses
                            </span>
                            :
                            null
                    }
                </div>
                <div className="d-flex align-items-center">
                    <CurrencyFormat
                        className={clsx(["form-control"], {
                            'border border-danger': hasError
                        })}
                        value={currentStaff?.total}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        onValueChange={(e) => handleChange({ target: { name: 'total', value: e.value } })}
                        placeholder="solicitada"
                    />
                    {
                        updatePlanStaffLoading &&
                        <div className="spinner" style={{ height: 15, width: 15, marginLeft: 10 }}>
                            <div className="double-bounce1 bg-primary"></div>
                            <div className="double-bounce2 bg-primary"></div>
                        </div>
                    }

                    {
                        !updatePlanStaffLoading && hasError ?
                            <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}

export default PlanStaffComponent;