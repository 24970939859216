import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import CurrencyFormat from "react-currency-format";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import { AiFillExclamationCircle } from "react-icons/ai";

const UpdatePlanStaff = ({
    planStaffId,
    defaultValue,
    name,
    canUpdate
}) => {
    const [hasError, setHasError] = useState(false);

    const [currentValue, setCurrentValue] = useState('');

    const [{ loading: loadingUpdatePlanStaff }, updatePlanStaff] = useAxios({ url: `/plan-staff/${planStaffId}/${name}`, method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (defaultValue) setCurrentValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e) => {
        if (canUpdate) {
            setHasError(false);
            try {

                setCurrentValue(e.target.value);

                updatePlanStaff({
                    data: {
                        value: Number(e.target.value)
                    }
                });

            } catch (error) {
            }
        }
    }

    return (
        <div style={{ width: 150 }}>
            <div className="input-group flex-nowrap">
                <CurrencyFormat
                    className={clsx(["form-control"], {
                        'border border-danger': hasError
                    })}
                    value={currentValue}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    readOnly={!planStaffId || !canUpdate}
                    onValueChange={(e) => handleChange({ target: { name: 'amount', value: e.value } })}
                    placeholder="Por favor ingrese el monto"
                />
                {
                    loadingUpdatePlanStaff &&
                    <span className="input-group-text" style={{ background: 'transparent' }}>
                        <Spinner variant="primary" style={{ width: 20, height: 20 }} animation="grow" />
                    </span>
                }
                {
                    !loadingUpdatePlanStaff && hasError ?
                        <span className="input-group-text p-0" style={{ background: 'transparent' }}>
                            <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                        </span>
                        :
                        null
                }
            </div>

        </div>
    )
}

export default UpdatePlanStaff;