import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap";
import { useFeedBack } from "../context/FeedBackContext";
import useAxios from "../hooks/useAxios";
import excelTemplate from "../assets/excels/plan-account-expense-template.xlsx"
import { FaCloudDownloadAlt } from "react-icons/fa";

const ImportPlanAccountExpense = ({ planId }) => {

    const { setLoading, setCustomAlert } = useFeedBack();

    const [showModal, setShowModal] = useState(false);

    const [data, setData] = useState({ file: '' })

    const [showValidRows, setShowValidRows] = useState(false);

    const [showInvalidRows, setShowInvalidRows] = useState(false);

    const [{ data: updateData, loading: updateLoading }, importValues] = useAxios({ url: `/plan-accounts/expense/import`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        setLoading({
            show: updateLoading,
            message: 'Importando registros'
        })
    }, [updateLoading]);

    useEffect(() => {
        if (updateData) {
            setShowValidRows(true);
            setShowInvalidRows(true);
            setCustomAlert({ show: true, message: "El archivo se ha importado correctamente.", severity: "success", title: 'Operación exitosa' })
        }
    }, [updateData])

    const handleSubmit = (e) => {
        e?.preventDefault?.();

        const dataToSend = new FormData();

        dataToSend.append('file', data?.file, data?.file?.name);
        dataToSend.append('planId', planId);

        importValues({ data: dataToSend });
    }

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <>
            <button className="btn btn-success btn-xs me-1" onClick={() => setShowModal(true)}>
                Importar gasto real
            </button>

            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Importe el archivo excel
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="text-end">
                            <a href={excelTemplate} download>
                                <FaCloudDownloadAlt />
                                Descargar plantilla
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="text-primary">Archivo excel</label>
                                    <br />
                                    <input
                                        type="file"
                                        name="file"
                                        className="form-control"
                                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            updateData?.message &&
                            <p className="text-success">
                                {updateData?.message}
                            </p>
                        }
                        {
                            updateData?.errors?.length > 0 && showInvalidRows ?
                                <div className="row">
                                    <h4 className="col-6 mb-3">
                                        Filas con errores: {updateData?.errors?.length}
                                    </h4>
                                    <div className="col-6 text-right">
                                        <button className="btn btn-danger" onClick={() => setShowInvalidRows(false)}>Eliminar</button>
                                    </div>
                                    <div className="col-md-12">
                                        {
                                            updateData?.errors?.map((error, i) => {
                                                return (
                                                    <div key={i} className="text-danger">
                                                        <p className="m-0"><b>En la fila: {error?.row}</b></p>
                                                        <ul>
                                                            {
                                                                error?.errors.map((errorDescription, i2) => {
                                                                    return (
                                                                        <li key={i2}>
                                                                            {errorDescription}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShowModal(false)}>
                            Cerrar
                        </Button>
                        <button disabled={updateLoading} className="btn btn-primary">
                            {
                                updateLoading ?
                                    'Cargando'
                                    :
                                    'Enviar'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ImportPlanAccountExpense