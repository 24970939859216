import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsEyeFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { Button, Dropdown } from "react-bootstrap";
import PlanningProcessHistoryLi from "../../components/PlanningProcessHistoryLi";
import DateFormatter from "../../components/DateFormatter";
import { dateFine } from "../../util/Utilities";
import clsx from "clsx";
import { formatDistanceToNowStrict } from "date-fns";
import es from "date-fns/locale/es";


const Dashboard = () => {

    const [filters, setFilters] = useState({
        name: ''
    });

    const [{ data: currentPlanningProcess, loading: loadingCurrentPlanningProcess }, getCurrentPlanningProcess] = useAxios({ url: `/planning-processes/current` }, { useCache: false });

    const [{ data: responseData, loading: costCentersLoading }, getCostCenters] = useAxios({ url: `my-account/cost-centers`, params: filters }, { useCache: false });

    const [{ data: costCentersForGeneralResponsibleResponse, loading: costCentersForGeneralResponsibleLoading }, getCostCentersForGeneralResponsible] = useAxios({ url: `my-account/cost-centers`, params: { forGeneralResponsible: true } }, { useCache: false });

    const [{ data: planningProcessResponse, loading: planningProcessLoading }, getPlanningProcess] = useAxios({ url: `/my-account/planning-processes` }, { useCache: false });

    const [currentCostCenters, setCurrentCostCenters] = useState([]);

    useEffect(() => {
        if (responseData) {
            setCurrentCostCenters(responseData?.data);
        }
    }, [responseData]);

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card p-2" style={{ position: 'relative' }}>
                        {
                            currentPlanningProcess?.data &&
                            <div className="spinner"
                                style={{ height: 15, width: 15, borderRadius: "100%", position: 'absolute', right: 15, top: 15 }}
                            >
                                <div
                                    className={clsx(["double-bounce1"], {
                                        "bg-success": currentPlanningProcess?.data?.open,
                                        "bg-danger": !currentPlanningProcess?.data?.open
                                    })}
                                />
                                <div
                                    className={clsx(["double-bounce2"], {
                                        "bg-success": currentPlanningProcess?.data?.open,
                                        "bg-danger": !currentPlanningProcess?.data?.open
                                    })}
                                />
                            </div>
                        }
                        <div className="card-header">
                            <div>
                                <h3>
                                    Planificación Actual
                                </h3>
                                {
                                    currentPlanningProcess?.data &&
                                    <p>
                                        Se esta planificando el año: {currentPlanningProcess?.data?.forYear}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                {
                                    currentPlanningProcess?.data ?
                                        <div className="w-100">
                                            <div className="row">
                                                <div className="col-md-4 text-center">
                                                    <h5>
                                                        Empieza
                                                    </h5>
                                                    <p>
                                                        <DateFormatter value={dateFine(currentPlanningProcess?.data?.start)} dateFormat="dd/MM/yyyy" />
                                                    </p>
                                                </div>
                                                <div className="col-md-4 text-center">
                                                    <h5>
                                                        Finaliza
                                                    </h5>
                                                    <p>
                                                        <DateFormatter value={dateFine(currentPlanningProcess?.data?.end)} dateFormat="dd/MM/yyyy" />
                                                    </p>
                                                </div>
                                                <div className="col-md-4 text-center">
                                                    <h5>
                                                        Quedan:
                                                    </h5>
                                                    <p>
                                                        {formatDistanceToNowStrict(new Date(dateFine(currentPlanningProcess?.data?.end)), {
                                                            locale: es
                                                        })}
                                                    </p>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <div className={clsx(["btn btn-block"], {
                                                        "btn-success": currentPlanningProcess?.data?.open,
                                                        "btn-danger": !currentPlanningProcess?.data?.open
                                                    })}>
                                                        {
                                                            !currentPlanningProcess?.data?.open ?
                                                                'Cerrado'
                                                                :
                                                                'Abierto'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h3 className="text-center">
                                                No se consiguieron resultados.
                                            </h3>
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card p-2" style={{ position: 'relative' }}>
                        <div className="card-header">
                            <div>
                                <h3>
                                    Centros de costos que debes aprobar:
                                </h3>
                                <p>
                                    Estos son los centros de costos a los que debes aprobar su planificación anual una vez terminada.
                                </p>
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                !costCentersForGeneralResponsibleResponse?.data?.length && !costCentersForGeneralResponsibleLoading ?
                                    <p className="text-center">
                                        No se encontrarón resultados.
                                    </p>
                                    :
                                    null
                            }
                            {
                                costCentersForGeneralResponsibleLoading &&
                                <div className="spinner" style={{ width: 50, height: 50 }}>
                                    <div className="double-bounce1 bg-primary"></div>
                                    <div className="double-bounce2 bg-primary"></div>
                                </div>
                            }
                            <ul>
                                {
                                    !costCentersForGeneralResponsibleLoading &&
                                    costCentersForGeneralResponsibleResponse?.data?.map((costCenter, i) => {
                                        return (
                                            <li key={i}>
                                                <a href={`/gestionar-centro-de-costo/${costCenter?.id}`}>
                                                    {costCenter?.name}
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card p-4">
                        <div>
                            <h3 className="my-3">Tus centros de costos</h3>
                            <div className="row">
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="Buscar"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {
                                    !costCentersLoading && currentCostCenters.length == 0 ?
                                        <div className="col-md-12 text-center">
                                            <h3>No se encontrarón centros de costo</h3>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    costCentersLoading &&
                                    <div className="spinner" style={{ width: 50, height: 50 }}>
                                        <div className="double-bounce1 bg-primary"></div>
                                        <div className="double-bounce2 bg-primary"></div>
                                    </div>
                                }
                                {
                                    !costCentersLoading &&
                                    currentCostCenters?.map((costCenter, i) => {
                                        return (
                                            <div className="col-md-3" key={i}>
                                                <div className="p-4">
                                                    <div>
                                                        <h5 className="text-center">
                                                            {costCenter?.name}
                                                        </h5>
                                                        <br />
                                                        <div>
                                                            <div className="d-flex align-items-center">
                                                                <Link to={`/gestionar-centro-de-costo/${costCenter?.id}`} className="btn btn-block btn-primary">
                                                                    Gestionar
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;