import CurrencyFormat from "react-currency-format";
import useAxios from "../../hooks/useAxios";
import DateFormatter from "../DateFormatter";
import PlanStaffComponent from "./PlanStaffComponent";
import PlanUfComponent from "./PlanUfComponent";

const PlanTotalSummary = ({ month, pathForUfAccounts, pathForSummary, pathForStaffUpdate, planId }) => {

    const [{ data, loading: planSummaryLoading }, getSummary] = useAxios({ params: { month }, url: pathForSummary }, { useCache: false });

    const [{ data: recalculateData, loading }, recalculate] = useAxios({ url: `/plans/${planId}/recalculate`, method: 'put' }, { manual: true, useCache: false });

    return (
        <div className="row mb-5">
            <div className="col-md-4" style={{ borderRight: '1px solid' }}>
                <PlanStaffComponent
                    staff={data?.staff}
                    userCanUpdate={data?.staff?.userCanUpdate && month}
                    pathForStaffUpdate={`${pathForStaffUpdate}/${data?.staff?.staffId}`}
                />
            </div>
            <div className="col-md-4">
                <PlanUfComponent
                    pathForUfAccounts={pathForUfAccounts}
                    uf={data?.uf}
                    userCanUpdate={data?.uf?.userCanUpdate && month}
                    month={month}
                />
            </div>
            <div className="col-md-4" style={{ borderLeft: '1px solid' }}>
                <h4>
                    Resumen:
                </h4>

                <div className="form-group mb-3">
                    <label className="text-primary">
                        Ingresos:
                    </label>
                    <CurrencyFormat
                        className="form-control"
                        prefix="$"
                        value={data?.totals?.totalIncome}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        placeholder="Ingresos"
                    />
                </div>

                <div className="form-group mb-3">
                    <label className="text-primary">
                        Gastos:
                    </label>
                    <CurrencyFormat
                        className="form-control"
                        prefix="$"
                        value={data?.totals?.totalSpent}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        placeholder="Gastos"
                    />
                </div>

                <div className="form-group mb-3">
                    <label className="text-primary">
                        Resultado:
                    </label>
                    <CurrencyFormat
                        className="form-control"
                        prefix="$"
                        value={data?.totals?.total}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        placeholder="Resultado"
                    />
                </div>
            </div>
            <div className="col-md-12 text-center">
                <button className="mx-1 btn btn-primary" onClick={() => getSummary()}>
                    Refrescar
                </button>
                <button disabled={loading} className="mx-1 btn btn-primary" onClick={() => recalculate()}>
                    {
                        loading ?
                            'Recalculando...'
                            :
                            'Recalcular'
                    }
                </button>
            </div>
        </div>
    )
}

export default PlanTotalSummary;