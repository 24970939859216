import { useEffect } from "react";
import useAxios from "../hooks/useAxios"
import LineChart from "./Charts/LineChart";

const PlanificationProgress = ({ planningProcessId, startOfThePlanification = '01/09' }) => {

    const [{ data, loading }] = useAxios({ url: `/planning-processes/${planningProcessId}/progress` }, { useCache: false });

    useEffect(() => {
        if (data) {
            console.log(data);
        }
    }, [data])

    return (
        <div className="col-md-12">
            <div className="card p-4">
                <h3>
                    Progreso de la planificacion durante el proceso:
                </h3>
                <br />

                {
                    loading ?
                        <h2>Cargando</h2>
                        :
                        <LineChart
                            categories={data?.map((value) => value?.monthAndDay)}
                            defaultSeries={[
                                {
                                    name: 'Finalizados',
                                    data: data?.map((value) => value?.count),
                                    color: '#5fda7a',
                                },
                                {
                                    name: 'Abiertos',
                                    data: data?.map((value) => value?.openCount),
                                    color: '#FF6746',
                                }
                            ]}
                            height={500}
                        />
                }



            </div>
        </div>
    )

}

export default PlanificationProgress;