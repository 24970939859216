import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import { dateFine, generateArray } from "../../../util/Utilities";
import DateFormatter from "../../../components/DateFormatter";
import usePaginatedResourceWithAppend from "../../../hooks/usePaginatedResourceWithAppend";
import clsx from "clsx";
import PieChart from "../../../components/Charts/PieChart";
import PlanAccountsForm from "../../../components/Plans/PlanAccountsForm";
import TabPanel from "../../../components/Tabs/TabPanel";
import { BsFilter } from "react-icons/bs";
import ColumnChart from "../../../components/Charts/ColumnChart";
import fileDownload from "js-file-download";
import { Image } from "react-bootstrap";
import profileImg from '../../../assets/images/profile.png';
import { AiFillCheckCircle } from "react-icons/ai";
import swal from "sweetalert";
import update from 'immutability-helper';
import PlanificationProgress from "../../../components/PlanificationProgress";
import PlanLiSummary from "../../../components/PlanLiSummary";


const PlanificationsEdit = () => {

    const { id } = useParams();

    const entity = {
        name: 'Proceso de planificación',
        url: 'planning-processes',
        frontendUrl: '/planificacion-de-gastos',
        camelName: 'planningProcesses',
    };

    const navigate = useNavigate();

    const [yearsOptions, setYearsOptions] = useState([]);

    const [showFilters, setShowFilters] = useState(false);

    const [responsiblesFilter, setResponsiblesFilter] = useState({
        search: ''
    });

    const [responsibleIds, setResponsibleIds] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(1);

    const [showPlanningAccountsTotal, setShowPlanningAccountsTotal] = useState(false);

    const [filters, setFilters] = useState({
        name: '',
        classificationId: '',
        code: '',
        type: ''
    });

    const [data, setData] = useState({
        start: '',
        end: '',
        forYear: new Date().getFullYear(),
        open: ''
    });

    const { setLoading, setCustomAlert } = useFeedBack();

    const [{ data: responsibles, loading: loadingResponsibles }, getResponsibles] = useAxios({ url: `/${entity?.url}/users-to-notificate`, params: responsiblesFilter }, { useCache: false });

    const [{ data: dataToUpdate, loading: loadingDataToUpdate }, getRecord] = useAxios({ url: `/${entity?.url}/${id}` }, { useCache: false });

    const [{ data: updateData, loading }, updateRecord] = useAxios({ url: `/${entity?.url}/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    const [{ data: planCount, loading: planCountLoading }, getPlanCount] = useAxios({ url: `/${entity?.url}/${id}/plans/count` }, { useCache: false });

    const [{ data: emailResponse, loading: emailLoading }, sendEmail] = useAxios({ url: `/${entity?.url}/send-emails`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data: accountClassificationsAmount, loading: loadingAccountClassificationsAmount }, getAccountClassificationsAmount] = useAxios({ url: `/${entity?.url}/${id}/total-by-account-classifications` }, { useCache: false });

    const [{ loading: loadingPlanExport }, exportPlanExcel] = useAxios({ url: `/${entity?.url}/${id}/excel`, responseType: 'blob' }, { useCache: false, manual: true })

    const [{ loading: loadingPlanKpiExport }, exportPlanKpiExcel] = useAxios({ url: `/${entity?.url}/${id}/kpi/excel`, responseType: 'blob' }, { useCache: false, manual: true })

    const [{ loading: loadingRecalculate }, recalculate] = useAxios({ url: `/${entity?.url}/${id}/recalculate`, method: 'PUT' }, { useCache: false, manual: true })

    const { loadMore, results: plans, loading: loadingPlans, canLoadMore, filters: planFilters, setFilters: setPlanFilters } = usePaginatedResourceWithAppend(`/planning-processes/${id}/plans`);

    const { loadMore: loadMoreAccountsClassifications, results: accountClassifications } = usePaginatedResourceWithAppend(`/account-classifications`);

    useEffect(() => {

        var year = new Date().getFullYear();

        var yearsBack = year - 50;

        var yearsNext = year + 50;

        var years = [];

        for (let index = yearsBack; index < yearsNext; index++) {
            years.push(index);
        }

        setYearsOptions(years);
    }, [])

    useEffect(() => {
        if (dataToUpdate) {
            setData((oldData) => {
                return {
                    ...oldData,
                    ...dataToUpdate?.data,
                    start: DateFormatter({ value: dateFine(dataToUpdate?.data?.start), dateFormat: 'yyyy-MM-dd' }),
                    end: DateFormatter({ value: dateFine(dataToUpdate?.data?.end), dateFormat: 'yyyy-MM-dd' }),
                    open: dataToUpdate?.data?.open ? 'si' : 'no',
                    ufs: dataToUpdate?.data?.ufs?.map((ufValue, i) => ufValue?.amount)
                }
            });
        }
    }, [dataToUpdate])

    useEffect(() => {
        setLoading({
            show: loading,
            message: 'Actualizando el registro'
        })
    }, [loading]);

    useEffect(() => {
        setLoading({
            show: loadingDataToUpdate,
            message: 'Obteniendo información'
        });
    }, [loadingDataToUpdate]);

    useEffect(() => {
        if (emailResponse) {
            setCustomAlert({
                show: true,
                severity: 'success',
                title: 'Operación Exitosa',
                message: 'Los emails fuerón enviados exitosamente.'
            });
        }
    }, [emailResponse])

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                show: true,
                severity: 'success',
                title: 'Operación Exitosa',
                message: 'El registro fue actualizado exitosamente.'
            });
            navigate(`${entity?.frontendUrl}/listar`);
        }
    }, [updateData])

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleFiltersChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault();

        updateRecord({ data });
    }

    const handleExport = async (withoutCostCenters) => {
        try {
            const exportPlanExcelResponse = await exportPlanExcel({
                params: {
                    withoutCostCenters
                }
            });

            fileDownload(exportPlanExcelResponse?.data, `Planificación de gastos (Resumen) - ${data?.forYear}.xlsx`);

        } catch (error) {
            alert('Ha ocurrido un error al descargar el excel.');
        }
    }

    const handleExportKpi = async (e) => {
        try {
            const exportPlanKpiExcelResponse = await exportPlanKpiExcel();

            fileDownload(exportPlanKpiExcelResponse?.data, `Planificación de gastos (KPI) - ${data?.forYear}.xlsx`);

        } catch (error) {
            alert('Ha ocurrido un error al descargar el excel.');
        }
    }

    const handleChangePLansFilter = (e) => {
        setPlanFilters((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleSendEmail = (emailType) => {
        swal({
            title: "¿Estas Seguro(a)?",
            text: `¿Quieres enviar estos correos?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willSend) => {
            if (willSend) {
                sendEmail({
                    data: {
                        responsibleIds,
                        emailType,
                        planningProcessId: id
                    }
                });
            }
        })
    }

    const handleResponsible = (responsibleId) => {

        const haveValue = responsibleIds?.includes(responsibleId);

        var newValues = [];

        if (haveValue) newValues = responsibleIds?.filter(value => value !== responsibleId);

        if (!haveValue) newValues = [...responsibleIds, responsibleId];

        setResponsibleIds(newValues);
    }

    const handleUpdateUfValue = (e, index) => {
        const newArray = update(data?.ufs, { [index]: { $set: Number(e.target.value) } });

        setData((oldData) => {
            return {
                ...oldData,
                ufs: newArray
            }
        })
    }

    return (
        <div>
            <div className="my-4 align-items-center justify-content-between d-flex">
                <h3>
                    Editar {entity?.name}
                </h3>
                {
                    <>
                        <Link to={`${entity?.frontendUrl}/listar`} className="btn btn-primary">
                            Volver al listado
                        </Link>
                    </>
                }
            </div>

            <form className="card p-4" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label className="text-primary">
                                Inicia <small className="text-danger">*</small>
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                onChange={handleChange}
                                name="start"
                                value={data?.start}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label className="text-primary">
                                Finaliza <small className="text-danger">*</small>
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                onChange={handleChange}
                                name="end"
                                value={data?.end}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label className="text-primary">
                                Planificación del año: <small className="text-danger">*</small>
                            </label>
                            <select
                                value={data?.forYear}
                                className="form-control"
                                name="forYear"
                                onChange={handleChange}
                            >
                                <option value="" disabled>Seleccione una opción</option>
                                {
                                    yearsOptions.map((year, i) => {
                                        return (
                                            <option value={year} key={i}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label className="text-primary">
                                Estado del proceso: <small className="text-danger">*</small>
                            </label>
                            <select
                                value={data?.open}
                                className="form-control"
                                name="open"
                                onChange={handleChange}
                            >
                                <option value="" disabled>Seleccione una opción</option>
                                <option value="no">Cerrado</option>
                                <option value="si">Abierto</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h3>
                            Valores UF por mes
                        </h3>
                        <small>Por favor ingrese el valor uf para cada mes.</small>
                    </div>
                    {
                        data?.ufs?.map((monthNumber, i) => {
                            return (
                                <div className="col-md-3 mb-3" key={i}>
                                    <div className="form-group">
                                        <label className="text-primary" style={{ textTransform: 'capitalize' }}>
                                            <DateFormatter value={`2023-${i + 1}-15 12:00:00`} dateFormat='LLLL' />
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={monthNumber}
                                            onChange={(e) => handleUpdateUfValue(e, i)}
                                            step=".01"
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <br />
                <div className="text-center">
                    <button className="btn btn-block btn-primary">
                        Actualizar
                    </button>
                </div>
            </form>
            <br /> <br />
            <div className="row">
                <div className="col-md-12">
                    <div className="card p-4">
                        <div className="card-header">
                            <h4>
                                Participantes
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre, Rut"
                                    value={responsiblesFilter?.search}
                                    onChange={(e) => setResponsiblesFilter((old) => {
                                        return {
                                            ...old,
                                            search: e.target.value
                                        }
                                    })}
                                />
                            </div>
                            <br />
                            <div className="text-end">
                                <button className="btn btn-xs btn-primary" onClick={() => {
                                    setResponsibleIds([])
                                }}>
                                    Remover todos
                                </button>
                                <button className="btn btn-xs btn-primary" onClick={() => {
                                    setResponsibleIds(responsibles?.data?.map(value => value?.id))
                                }}>
                                    Seleccionar todos
                                </button>
                            </div>
                            <br />
                            <ul className="custom-scrollbar scrollbar-primary" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                {
                                    loadingResponsibles &&
                                    <div className="spinner">
                                        <div className="double-bounce1 bg-primary"></div>
                                        <div className="double-bounce2 bg-primary"></div>
                                    </div>
                                }
                                {
                                    !loadingResponsibles &&
                                    responsibles?.data?.map((responsible, i) => {
                                        return (
                                            <li
                                                key={i}
                                                className="mb-2 custom-responsible-option px-1 py-2"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleResponsible(responsible?.id)}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div style={{ marginRight: 10 }} className="text-primary">
                                                        {i + 1}
                                                    </div>
                                                    <div style={{ marginRight: 10 }}>
                                                        <Image style={{ height: 40, width: 40 }} src={responsible?.imagePath || profileImg} roundedCircle />
                                                    </div>
                                                    <div style={{ marginRight: 10 }}>
                                                        <b>Nombre:</b> {responsible?.name}
                                                        <br />
                                                        <b>Rut:</b> {responsible?.documentNumber}
                                                        <br />
                                                        <b>Email:</b> {responsible?.email}
                                                    </div>
                                                    {
                                                        responsibleIds.includes(responsible?.id) ?
                                                            <div style={{ marginLeft: 'auto' }}>
                                                                <AiFillCheckCircle className="text-primary" style={{ fontSize: 22 }} />
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className="text-center">
                                {
                                    emailLoading ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <>
                                            <button
                                                onClick={(e) => handleSendEmail('create')}
                                                className="btn btn-xs btn-primary mx-1"
                                                disabled={!responsibleIds || responsibleIds.length < 1}
                                            >
                                                Reenviar correo de inicio de planificación
                                            </button>
                                            <button
                                                onClick={(e) => handleSendEmail('remember')}
                                                className="btn btn-xs btn-primary mx-1"
                                                disabled={!responsibleIds || responsibleIds.length < 1}
                                            >
                                                Recordar planificación
                                            </button>
                                            <button
                                                onClick={(e) => handleSendEmail('finalize')}
                                                className="btn btn-xs btn-primary mx-1"
                                                disabled={!responsibleIds || responsibleIds.length < 1}
                                            >
                                                Enviar correo de finalización
                                            </button>
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card p-4">
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <h3>Estatus de los centros de costos</h3>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-center">
                                        <div>
                                            <p className="text-success">
                                                Finalizados
                                            </p>
                                        </div>
                                        {
                                            planCountLoading ?
                                                <div className="spinner">
                                                    <div className="double-bounce1 bg-primary"></div>
                                                    <div className="double-bounce2 bg-primary"></div>
                                                </div>
                                                :
                                                <h4>
                                                    {planCount?.closed}
                                                </h4>
                                        }
                                    </div>
                                    <div className="text-center">
                                        <p className="text-danger">
                                            Pendientes
                                        </p>
                                        {
                                            planCountLoading ?
                                                <div className="spinner">
                                                    <div className="double-bounce1 bg-primary"></div>
                                                    <div className="double-bounce2 bg-primary"></div>
                                                </div>
                                                :
                                                <h4>
                                                    {planCount?.open}
                                                </h4>
                                        }
                                    </div>
                                </div>
                                <br />
                                <div className="form-group">
                                    <select name="showOnly" className="form-control" onChange={handleChangePLansFilter}>
                                        <option value="">Seleccione una opción:</option>
                                        <option value="closed">Finalizados</option>
                                        <option value="no_closed">Pendientes</option>
                                        <option value="approved">Revisados</option>
                                        <option value="no_approved">Pendientes por revisar</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <input
                            type="text"
                            className="form-control mb-4"
                            placeholder="Buscar..."
                            value={planFilters?.costCenterName}
                            name="costCenterName"
                            onChange={handleChangePLansFilter}
                        />
                        <ul className="container custom-scrollbar scrollbar-primary" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                            {
                                plans?.map((plan, i) => {
                                    return (
                                        <PlanLiSummary plan={plan} key={i} />
                                    )
                                })
                            }
                            {
                                loadingPlans &&
                                <li>
                                    <div className="spinner my-5">
                                        <div className="double-bounce1 bg-primary"></div>
                                        <div className="double-bounce2 bg-primary"></div>
                                    </div>
                                </li>
                            }
                            {
                                !loadingPlans && canLoadMore ?
                                    <li className="text-center">
                                        <button className="btn btn-primary btn-xs" onClick={() => loadMore()}>
                                            Cargar más
                                        </button>
                                    </li>
                                    :
                                    null
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card p-4">
                        <h4>
                            % Pendientes / Finalizados
                        </h4>
                        <br />
                        {
                            planCountLoading ?
                                <div className="spinner my-5">
                                    <div className="double-bounce1 bg-primary"></div>
                                    <div className="double-bounce2 bg-primary"></div>
                                </div>
                                :
                                <PieChart
                                    title={""}
                                    labels={['Pendientes', 'Finalizados']}
                                    defaultSeries={[planCount?.open, planCount?.closed]}
                                    colors={['#FF6746', '#37D159']}
                                />
                        }

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card p-4">
                        <div className="d-flex align-items-center justify-content-between mb-5">
                            <h3 className="mb-4">
                                Resumen del plan:
                            </h3>
                            <div>
                                <button disabled={loadingPlanKpiExport} className="btn btn-success btn-xs mx-3" onClick={handleExportKpi}>
                                    {
                                        loadingPlanKpiExport ?
                                            'Cargando...'
                                            :
                                            'Generar excel KPI'
                                    }
                                </button>

                                <button disabled={loadingPlanExport} className="btn btn-success btn-xs mx-3" onClick={() => handleExport(true)}>
                                    {
                                        loadingPlanExport ?
                                            'Cargando'
                                            :
                                            'Generar excel Resumen Sin Centros de Costo'
                                    }
                                </button>

                                <button disabled={loadingPlanExport} className="btn btn-success btn-xs mx-3" onClick={() => handleExport()}>
                                    {
                                        loadingPlanExport ?
                                            'Cargando'
                                            :
                                            'Generar excel Resumen'
                                    }
                                </button>

                                <button disabled={loadingRecalculate} className="btn btn-primary btn-xs mx-3" onClick={() => recalculate()}>
                                    {
                                        loadingRecalculate ?
                                            'Cargando'
                                            :
                                            'Recalcular'
                                    }
                                </button>
                                <button className="btn btn-primary btn-xs mx-3" onClick={() => setShowPlanningAccountsTotal(old => !old)}>
                                    {showPlanningAccountsTotal ? 'Ocultar' : 'Mostrar'}
                                </button>
                            </div>
                        </div>
                        {
                            showPlanningAccountsTotal &&
                            <>
                                <div className="card p-3" style={{ position: 'fixed', top: '30vh', left: showFilters ? '0' : '-15vw', width: '15vw', background: 'white', height: 'fit-content' }}>
                                    <button onClick={() => setShowFilters(old => !old)} className="btn btn-primary" title="Filtrar cuentas" style={{ position: 'absolute', left: '100%', top: 0 }}>
                                        <BsFilter />
                                    </button>
                                    <h4>Filtrar Cuentas</h4>
                                    <div>
                                        <input
                                            name="name"
                                            value={filters?.name}
                                            onChange={handleFiltersChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Nombre"
                                        />
                                        <br />
                                        <input
                                            name="code"
                                            value={filters?.code}
                                            onChange={handleFiltersChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Código"
                                        />
                                        <br />
                                        <select
                                            name="classificationId"
                                            value={filters?.classificationId}
                                            className="form-control"
                                            onChange={handleFiltersChange}
                                        >
                                            <option value="">Clasificación</option>
                                            {
                                                accountClassifications?.map((classification, i) => {
                                                    return (
                                                        <option value={classification?.id} key={i}>{classification?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <br />
                                        <select
                                            name="type"
                                            value={filters?.type}
                                            className="form-control"
                                            onChange={handleFiltersChange}
                                        >
                                            <option value="">Tipo</option>
                                            <option value="spent">Gasto</option>
                                            <option value="income">Ingreso</option>
                                        </select>
                                        <br />
                                        <div className="text-center">
                                            <button
                                                onClick={() => setFilters({ name: '', type: '', classificationId: '', code: '' })}
                                                className="btn btn-primary btn-xs"
                                            >
                                                Reestablecer
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <ul className="d-flex align-items-center w-100" style={{ flexWrap: 'wrap' }}>
                                    {
                                        generateArray(12, 1)?.map((monthNumber, i) => {
                                            return (
                                                <li
                                                    onClick={() => setCurrentMonth(monthNumber)}
                                                    className={
                                                        clsx(["btn"], {
                                                            'btn-primary': monthNumber === currentMonth
                                                        })
                                                    }
                                                    key={i}
                                                    style={{ marginRight: 15, textTransform: 'capitalize' }}
                                                >
                                                    <DateFormatter value={`2023-${monthNumber}-15 12:00:00`} dateFormat="LLLL" />
                                                </li>
                                            )
                                        })
                                    }
                                    <li
                                        onClick={() => setCurrentMonth('year')}
                                        className={
                                            clsx(["btn"], {
                                                'btn-primary': 'year' === currentMonth
                                            })
                                        }
                                        style={{ marginRight: 15, textTransform: 'capitalize' }}
                                    >
                                        Resultado del Año
                                    </li>
                                </ul>
                                <br /><br /><br />
                                {
                                    generateArray(12, 1).map((monthNumber, i) => {
                                        return (
                                            <TabPanel eventKey={monthNumber} value={currentMonth} key={i}>
                                                <PlanAccountsForm
                                                    additionalFilters={filters}
                                                    month={monthNumber}
                                                    pathForAccounts={`/${entity?.url}/${id}/accounts`}
                                                    pathForSummary={`/${entity?.url}/${id}/summary`}
                                                    disabledAccounts
                                                />
                                            </TabPanel>
                                        )
                                    })
                                }

                                <TabPanel eventKey={'year'} value={currentMonth}>
                                    <PlanAccountsForm
                                        additionalFilters={filters}
                                        pathForAccounts={`/${entity?.url}/${id}/accounts`}
                                        pathForSummary={`/${entity?.url}/${id}/summary`}
                                        disabledAccounts
                                    />
                                </TabPanel>
                            </>
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card p-4">
                        <h3>
                            Resumen por clasificación de cuentas:
                        </h3>
                        <br />
                        {
                            accountClassificationsAmount &&
                            <ColumnChart
                                categories={accountClassificationsAmount?.data?.map(value => value?.name)}
                                title=""
                                defaultSeries={[
                                    {
                                        name: 'Total',
                                        data: accountClassificationsAmount?.data?.map(value => value?.amount ? Number(value?.amount) : 0)
                                    }
                                ]}
                                labelStartAdornment="$"
                            />
                        }
                    </div>
                </div>
                {
                    dataToUpdate?.data &&
                    <PlanificationProgress
                        startOfThePlanification={DateFormatter({ value: dateFine(dataToUpdate?.data?.start), dateFormat: 'dd/MM' })}
                        planningProcessId={id}
                    />
                }

            </div>
        </div >
    )
}

export default PlanificationsEdit;