import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import CurrencyFormat from "react-currency-format";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdGroup } from "react-icons/md";


const UpdatePlanUf = ({
    financialUnitAccountId,
    defaultValue,
    closedAt,
    approveAt,
    canUpdate
}) => {
    const [hasError, setHasError] = useState(false);

    const [currentValue, setCurrentValue] = useState('');

    const [{ data: updatePlanAccountData, loading: loadingUpdatePlanAccount }, updatePlanAccount] = useAxios({ url: `/financial-unit-accounts/${financialUnitAccountId}`, method: 'PUT' }, { useCache: false, manual: true });

    useEffect(() => {
        if (defaultValue) setCurrentValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (e) => {
        setHasError(false);
        if (canUpdate) {
            try {

                setCurrentValue(e.target.value);

                updatePlanAccount({
                    data: {
                        amount: Number(e.target.value)
                    }
                });

            } catch (error) {
            }
        }
    }

    return (
        <div style={{ width: 150 }}>
            <div className="input-group flex-nowrap">
                <CurrencyFormat
                    className={clsx(["form-control"], {
                        'border border-danger': hasError,
                        'border border-success': !hasError && currentValue > 0,
                        'border border-warning': !hasError && currentValue < 0,
                        'cursor-not-allowed': !financialUnitAccountId || closedAt || approveAt
                    })}
                    value={currentValue}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    readOnly={!financialUnitAccountId || closedAt || approveAt || !canUpdate}
                    title={!financialUnitAccountId ? 'Este valor es solo de muestra no puede ser editado.' : closedAt ? 'El plan ya ha sido cerrado. Para poder edirtarlo debe de abrir el plan.' : approveAt ? 'El plan ya ha sido cerrado y aprobado para poder editarlo debe abrirlo de nuevo' : null}
                    suffix="UF"
                    onValueChange={(e) => handleChange({ target: { name: 'amount', value: e.value } })}
                    placeholder="Por favor ingrese el monto"
                />
                {
                    loadingUpdatePlanAccount &&
                    <span className="input-group-text" style={{ background: 'transparent' }}>
                        <Spinner variant="primary" style={{ width: 20, height: 20 }} animation="grow" />
                    </span>
                }
                {
                    !loadingUpdatePlanAccount && hasError ?
                        <span className="input-group-text p-0" style={{ background: 'transparent' }}>
                            <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                        </span>
                        :
                        null
                }
            </div>

        </div>
    )
}

export default UpdatePlanUf;