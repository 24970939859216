import clsx from "clsx"
import { Link } from "react-router-dom"
import DateFormatter from "./DateFormatter"
import { dateFine } from "../util/Utilities"
import { AiOutlineReload } from "react-icons/ai"
import useAxios from "../hooks/useAxios"

const PlanLiSummary = ({ plan }) => {

    const [{ data, loading }, recalculate] = useAxios({ url: `/plans/${plan?.id}/recalculate`, method: 'put' }, { manual: true, useCache: false });

    return (
        <li className="row" >
            <div className="col-2">
                <Link to={`/centros-de-costos/plans/${plan?.id}`}>
                    <b>
                        {plan?.costCenter?.name}
                    </b>
                </Link>
            </div>
            <div className="col-3">
                <p className={clsx({
                    'text-success': plan?.totalSpent < plan?.totalIncome,
                    'text-danger': plan?.totalSpent > plan?.totalIncome,
                })}>
                    <b>Resultado:</b>
                    <br />
                    $ {Number(plan?.total).toLocaleString()}
                </p>
            </div>
            <div className="col-3 text-end">
                {
                    plan?.closedAt ?
                        <span className="btn btn-success btn-xs">
                            Finalizado el: <span style={{ textTransform: 'capitalize' }}>
                                <DateFormatter value={dateFine(plan?.closedAt)} dateFormat="dd LLLL" />
                            </span>
                        </span>
                        :
                        <span className="btn btn-danger btn-xs">
                            Gestionando
                        </span>
                }
            </div>
            <div className="col-2 text-end">
                {
                    plan?.approvedAt ?
                        <span className="btn btn-success btn-xs">
                            Revisado el: <span style={{ textTransform: 'capitalize' }}>
                                <DateFormatter value={dateFine(plan?.approvedAt)} dateFormat="dd LLLL" />
                            </span>
                        </span>
                        :
                        <span className="btn btn-danger btn-xs">
                            Sin revisar
                        </span>
                }
            </div>
            <div className="col-1 text-center">
                <button disabled={loading} onClick={() => recalculate()} className="m-auto btn btn-primary btn-xs" title="Recalcular">
                    <AiOutlineReload />
                </button>
            </div>
        </li >
    )
}

export default PlanLiSummary