import { useEffect, useState } from "react";
import DateFormatter from "../DateFormatter";
import useAxios from "../../hooks/useAxios";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import { AiFillExclamationCircle } from "react-icons/ai";
import clsx from "clsx";

const PlanUfComponent = ({ uf, month, userCanUpdate, pathForUfAccounts }) => {

    const [currentUf, setCurrentUf] = useState(null);

    const [{ loading: loadingUpdateUfAccount, error }, updatePlanUf] = useAxios({ url: `${pathForUfAccounts}/${uf?.ufId}`, method: 'PUT' }, { manual: true, useCache: false });

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (error) {
            setHasError(true);
        }
    }, [error])

    useEffect(() => {
        if (uf) {
            setCurrentUf(uf);
        }
    }, [uf])

    const handleChange = (e) => {

        if (!userCanUpdate || !uf?.ufId) return alert('No tienes permisos.');

        if (currentUf?.ufId) {
            updatePlanUf({
                data: {
                    amount: e.target.value,
                    applyForAllMonths: 'no'
                }
            });
        }

        setCurrentUf((oldValue) => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleApply = () => {

        if (!userCanUpdate || !uf?.ufId) return alert('No tienes permisos.');


        swal({
            title: "¿Estas Seguro(a)?",
            text: "Esto sobreescribira los valores de los demas meses.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willUpdate) => {
            if (willUpdate) updatePlanUf({
                data: {
                    amount: Number(currentUf?.amount),
                    applyForAllMonths: 'si'
                }
            })
        })

    }

    return (
        <>
            <h4>
                Ingresos UF:
            </h4>

            <div className="form-group mb-3">
                <div className="d-flex align-items-center justify-content-between">
                    <label htmlFor="" className="text-primary">
                        Ingresos
                    </label>
                    {
                        userCanUpdate && uf?.ufId ?
                            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={handleApply}>
                                Aplicar a los demas meses
                            </span>
                            :
                            null
                    }
                </div>
                <div className="d-flex align-items-center">
                    <CurrencyFormat
                        className={clsx(["form-control"], {
                            'border border-danger': hasError
                        })}
                        value={currentUf?.amount}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        onValueChange={(e) => handleChange({ target: { name: 'amount', value: e.value } })}
                        placeholder="Ingresos uf"
                    />
                    {
                        loadingUpdateUfAccount &&
                        <div className="spinner" style={{ height: 15, width: 15, marginLeft: 10 }}>
                            <div className="double-bounce1 bg-primary"></div>
                            <div className="double-bounce2 bg-primary"></div>
                        </div>
                    }

                    {
                        !loadingUpdateUfAccount && hasError ?
                            <AiFillExclamationCircle className="text-danger" style={{ marginLeft: 10, fontSize: 15 }} />
                            :
                            null
                    }
                </div>
            </div>

            {
                currentUf?.ufId &&
                <div className="form-group mb-3">
                    <label className="text-primary">
                        Tasa del mes de <DateFormatter value={`2023-${month}-15 12:00:00`} dateFormat='LLLL' />:
                    </label>
                    <CurrencyFormat
                        className="form-control"
                        value={currentUf?.rate}
                        decimalSeparator=","
                        thousandSeparator="."
                        isNumericString={true}
                        placeholder="Tasa..."
                        prefix="$"
                    />
                </div>
            }

            <div className="form-group mb-3">
                <label className="text-primary">
                    Total en $:
                </label>
                <CurrencyFormat
                    className="form-control"
                    value={currentUf?.total}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString={true}
                    placeholder="Total..."
                    prefix="$"
                />
            </div>
        </>
    )
}

export default PlanUfComponent;