import { useEffect, useState } from "react";
import ActivityLogsColumns from "../../../components/CustomTable/Columns/ActivityLogsColumns";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { useFeedBack } from "../../../context/FeedBackContext";
import useAxios from "../../../hooks/useAxios";
import useActivityLogs from "../../../hooks/useActivityLogs";

const ActivityLogs = () => {

    const entity = {
        name: 'Registro de actividades',
        url: 'activity-logs',
        frontendUrl: '/registro-de-actividades',
        camelName: 'activityLogs',
    };

    const { setCustomAlert, setLoading } = useFeedBack();

    const [filters, setFilters] = useState({
        page: 1,
        name: '',
        search: ''
    });

    const [selectedValues, setSelectedValues] = useState([]);

    const [selectAll, setSelectAll] = useState(false);

    const [{ [entity.camelName]: records, total, numberOfPages, loading }, getRecords] = useActivityLogs({ params: { ...filters }, options: { useCache: false } });

    const [{ error: deleteError, loading: deleteLoading }, deleteRecord] = useAxios({ method: 'DELETE' }, { manual: true, useCache: false });

    useEffect(() => {
        getRecords({
            params: filters
        });
    }, [filters])

    useEffect(() => {
        setLoading?.({
            show: deleteLoading,
            message: 'Eliminando Registros'
        })
    }, [deleteLoading])

    useEffect(() => {
        if (deleteError) {
            setCustomAlert({
                title: 'error',
                severity: 'danger',
                message: 'Ha ocurrido un error al eliminar.',
                show: true
            });
        }
    }, [deleteError])

    useEffect(() => {
        if (selectAll) {
            setSelectedValues(records?.map?.((value) => value?.id))
        } else {
            setSelectedValues([])
        }
    }, [selectAll])

    const handleDelete = (value) => {
        deleteRecord({ url: `${entity.url}/${value?.id}` }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'El registros ha sido eliminado exitosamente.',
                show: true
            });
            getRecords();
        })
    }

    const handleSelectALL = () => {
        setSelectAll((oldSelectAll) => !oldSelectAll);
    }

    const handleSelectValue = (selectedValue) => {
        const value = selectedValues?.includes(Number(selectedValue?.id));
        if (value) {
            const newValues = selectedValues?.filter(n => n !== Number(selectedValue?.id));
            setSelectedValues(newValues);
        } else {
            setSelectedValues((oldSelectedValues) => [...oldSelectedValues, Number(selectedValue?.id)])
        }
    }

    const handlePageChange = (page) => {
        if (numberOfPages >= page && page > 0) {
            setFilters((oldFilters) => {
                return {
                    ...oldFilters,
                    page: page
                }
            })
        }
    }

    const handleChangeFilters = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleDeleteSelected = () => {
        deleteRecord({ url: `${entity.url}/multiple`, data: { ids: selectedValues } }).then((data) => {
            setCustomAlert({
                title: '¡Operación Exitosa!',
                severity: 'success',
                message: 'Los registros han sido eliminados exitosamente.',
                show: true
            })
            setSelectedValues([]);
            getRecords();
        });
    }

    return (
        <div>
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header text-primary">
                            Tabla: <input
                                type="text"
                                style={{ marginLeft: 10 }}
                                placeholder="Nombre de la tabla"
                                value={filters?.table} onChange={handleChangeFilters}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header text-primary">
                            Acción: <select
                                style={{ marginLeft: 10 }}
                                className="form-control"
                                onChange={handleChangeFilters}
                                name="action"
                                value={filters?.action}
                            >
                                <option value="">Selecciona una opción</option>
                                <option value="create">Crear</option>
                                <option value="update">Actualizar</option>
                                <option value="delete">Eliminar</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header text-primary">
                            Usuario: <input
                                type="text"
                                style={{ marginLeft: 10 }}
                                className="form-control"
                                value={filters?.userName}
                                name="userName"
                                onChange={handleChangeFilters}
                                placeholder="Nombre del usuario"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body text-primary">
                            Descripción: <input
                                type="text"
                                style={{ marginLeft: 10 }}
                                className="form-control"
                                value={filters?.description}
                                name="description"
                                onChange={handleChangeFilters}
                                placeholder="Descripción de la acción"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="" className="text-primary">Desde</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={filters?.start}
                                            name="start"
                                            onChange={handleChangeFilters}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="" className="text-primary">Hasta</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={filters?.end}
                                            name="end"
                                            onChange={handleChangeFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomTable
                onDeleteSelected={handleDeleteSelected}
                onSelectValue={handleSelectValue}
                onSelectAll={handleSelectALL}
                loading={loading}
                selectAll={selectAll}
                title={entity?.name}
                entity={entity.url}
                updatePath={entity.frontendUrl}
                updateOptionString={'Editar'}
                onDelete={handleDelete}
                selectedValues={selectedValues}
                pages={numberOfPages}
                total={total}
                values={records}
                currentPage={filters?.page}
                collumns={ActivityLogsColumns}
                changePage={handlePageChange}
                filters={filters}
                excelUrl={`${entity.url}/export/excel`}
                perPage={filters?.perPage}
                onPerPageChange={(e) => setFilters((oldFilters) => {
                    return {
                        ...oldFilters,
                        [e.target.name]: e.target.value
                    }
                })}
            />
        </div>
    )
}

export default ActivityLogs;